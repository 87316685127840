import { apiSlice } from '../api/apiSlice';

export const notificationApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getNotifications: builder.query({
			query: (urlStr) => `/notifications${urlStr}`,
			providesTags: ['notifications'],
			keepUnusedDataFor: 30,
		}),

		addNotification: builder.mutation({
			query: (data) => ({
				url: `/notifications`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: (result) => {
				if (result) {
					return ['notifications'];
				}
			},
		}),

		showNotification: builder.query({
			query: ({ id }) => `/notifications/${id}`,
		}),

		updateNotification: builder.mutation({
			query: ({ id, data }) => ({
				url: `/notifications/${id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: (result) => {
				if (result) {
					return ['notifications'];
				}
			},
		}),

		deleteNotification: builder.mutation({
			query: (id) => ({
				url: `/notifications/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result) => {
				if (result) {
					return ['notifications'];
				}
			},
		}),
		getUserNotification: builder.query({
			query: () => `/notifications?with_additional_data=true`,
			providesTags: ['notifications'],
		}),
	}),
});

export const {
	useGetNotificationsQuery,
	useAddNotificationMutation,
	useShowNotificationQuery,
	useUpdateNotificationMutation,
	useDeleteNotificationMutation,
	useGetUserNotificationQuery,
} = notificationApi;
