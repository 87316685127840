import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import React, { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltips from '../../components/bootstrap/Tooltips';
import ThemeContext from '../../contexts/themeContext';
import useAsideTouch from '../../hooks/useAsideTouch';
import { dashboardMenu } from '../../menu';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import {
	accounting,
	complains,
	consignee,
	containers,
	customers,
	exports,
	gatepass,
	loadPlan,
	pricing,
	settings,
	subuser,
	vcc,
	vehicle,
} from './menus';
interface AuthState {
	auth: any;
}

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { permissions, user } = useSelector((state: AuthState) => state.auth);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc] = useState(false);

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					{/* <img
						src={`${process.env.REACT_APP_BASE_URL}/images/logo.jpg`}
						alt=''
						height={60}
						width={'100%'}
					/> */}
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					<NavigationLine />
					{!doc && (
						<>
							{permissions?.['customers.index'] && (
								<Navigation menu={customers(permissions)} id='customers-pages' />
							)}
							{user?.role == 4 && (
								<Navigation menu={subuser()} id='customers-pages' />
							)}
							{permissions?.['consignees.index'] && (
								<Navigation menu={consignee(permissions)} id='consignee-pages' />
							)}
							{/* <Navigation menu={vehiclePages(permissions)} id='aside-demo-pages' /> */}
							<Navigation menu={vehicle(permissions)} id='vehicle-pages' />
							{permissions?.['exports.index'] && (
								<Navigation menu={exports(permissions)} id='export-pages' />
							)}
							{permissions?.['vccs.index'] && (
								<Navigation menu={vcc(permissions)} id='vcc-pages' />
							)}
							{permissions?.['containers.index'] && (
								<Navigation menu={containers(permissions)} id='containers-page' />
							)}
							{user?.role == 1 && (
								<Navigation menu={gatepass()} id='gate-pass-page' />
							)}
							<Navigation menu={pricing(permissions)} id='price-page' />

							{permissions?.['complains.index'] && (
								<Navigation menu={complains(permissions)} id='complain-page' />
							)}

							{permissions?.['load-plans.index'] && (
								<Navigation menu={loadPlan(permissions)} id='load-plan-page' />
							)}

							{/* {(user?.role == '1' ||
								user?.role == '2' ||
								user?.role == '3' ||
								user?.role == '4' ||
								user?.role == '5') && (
									<>
										<NavigationLine />
										<Navigation menu={accounts(permissions)} id='accounts-page' />
									</>
								)} */}

							{user?.role == 4 && (
								<Navigation menu={accounting()} id='accounts-page' />
							)}

							{user?.accounting_login_url && user?.role != 4 && (
								<nav aria-label='load-plan-page'>
									<ul id='load-plan-page' className='navigation'>
										<li className='navigation-item'>
											<a
												className='navigation-link navigation-link-pill'
												href={user?.accounting_login_url}
												target='_blank'
												rel='noreferrer'>
												<span className='navigation-link-info'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														height='1em'
														viewBox='0 0 24 24'
														width='1em'
														fill='currentColor'
														className='svg-icon--material svg-icon navigation-icon'
														data-name='Material--AccountBalance'>
														<path
															d='M0 0h24v24H0V0z'
															fill='none'></path>
														<path
															d='M6.29 6l5.21-2.74L16.71 6z'
															opacity='0.3'></path>
														<path d='M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-9L2 6v2h19V6l-9.5-5zM6.29 6l5.21-2.74L16.71 6H6.29z'></path>
													</svg>
													<span className='navigation-text'>
														Accounting
													</span>
												</span>
											</a>
										</li>
									</ul>
								</nav>
							)}

							{(user?.role == '1' || user?.role == '2') && (
								<>
									<NavigationLine />
									<Navigation
										menu={settings(permissions, user?.role)}
										id='settings-page'
									/>
								</>
							)}

							{/* <Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' />
							<NavigationLine /> */}
						</>
					)}

					{/* {asideStatus && doc && <div className='p-4'>Documentation</div>} */}
				</div>
				<div className='aside-foot'>
					{/* <nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : undefined}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span>
							</div>
						</div>
					</nav> */}
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
