import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Alert from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';

import { useLoginMutation } from '../../../features/auth/authApi';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

const Login = () => {
	const { accessToken } = useSelector((state) => state.auth);

	const [login, { isLoading, error: responseError }] = useLoginMutation();

	const { darkModeStatus } = useDarkMode();
	const [error, setError] = useState('');
	const [passType, setPassType] = useState(false);

	useEffect(() => {
		// Add a class to the body element
		document.body.classList.add('bg-login');

		// Cleanup by removing the class when the component unmounts
		return () => {
			document.body.classList.remove('bg-login');
		};
	}, []);

	useEffect(() => {
		if (responseError?.data) {
			setError(responseError.data?.error);
		}
	}, [responseError]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Username can not be blank';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Password can not be blank';
			}
			return errors;
		},
		onSubmit: (values) => {
			setError('');

			login({
				email: values.loginUsername,
				password: values.loginPassword,
			});
		},
	});

	/** Submit by Enter */
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			formik.handleSubmit();
		}
	};

	return accessToken ? (
		<Navigate to='/' />
	) : (
		<PageWrapper isProtected={false} title={'Login'}>
			<Page className='p-0'>
				<div className='h-100 d-flex align-items-center justify-content-center'>
					<div className='card card-login'>
						<div className='row g-0'>
							<div className='col-md-6'>
								<div className='img-content'></div>
							</div>
							<div className='col-md-6'>
								<div className='login-content'>
									<div className='text-center mb-5'>
										<div
											className={classNames(
												'text-decoration-none  fw-bold display-2',
												{
													'text-dark': !darkModeStatus,
													'text-light': darkModeStatus,
												},
											)}>
											<img
												src={`${process.env.REACT_APP_BASE_URL}/images/logo.png`}
												alt='Logo'
												style={{ maxWidth: '200px' }}
											/>
										</div>
									</div>
									<div
										className={classNames('rounded-3', {
											'bg-l10-dark': !darkModeStatus,
											'bg-dark': darkModeStatus,
										})}></div>

									<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
									<div className='text-center h4 text-muted mb-5'>
										Sign in to continue!
									</div>

									<form className='row g-4'>
										<>
											{error && (
												<Alert
													isLight
													color='danger'
													icon='Lock'
													isDismissible>
													<div className='row'>
														<div className='col-12'>
															<strong> {error}</strong>
														</div>
													</div>
												</Alert>
											)}
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email or username'
													style={{ marginBottom: '20px' }}>
													<Input
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
													/>
												</FormGroup>
												<div className='input-wrapper-icon'>
													<FormGroup
														id='loginPassword'
														isFloating
														className=''
														label='Password'>
														<Input
															type={passType ? 'text' : 'password'}
															value={formik.values.loginPassword}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onKeyDown={handleKeyDown}
														/>
													</FormGroup>
													{!formik.errors.loginPassword && (
														<span
															className='icon-hodlder'
															onClick={() => setPassType(!passType)}>
															<Icon
																size='lg'
																icon={passType ? 'Eye' : 'EyeOff'}
															/>
														</span>
													)}
												</div>
											</div>

											<div className='col-12'>
												<Button
													color='success'
													className='w-100 py-3'
													onClick={formik.handleSubmit}
													isDisable={isLoading}>
													Login
													{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
												</Button>
											</div>
										</>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default Login;
