import { lazy } from 'react';
import {
	accounts,
	complains,
	consignee,
	containers,
	customerTitleStatus,
	customers,
	damageClaim,
	dashboardMenu,
	demoPages,
	exports,
	gatepass,
	keyMissingClaim,
	layoutMenu,
	loadPlan,
	pricing,
	profiles,
	settings,
	storageClaim,
	vcc,
	vehicles,
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const SETTINGS = {
	USER: lazy(() => import('../pages/settings/users/User')),
	USER_ROLE: lazy(() => import('../pages/settings/users/UserPermission')),
	ROLE: lazy(() => import('../pages/settings/roles/Role')),
	ROLE_UPDATE: lazy(() => import('../pages/settings/roles/UserRoleUpdate')),
	COUNTRY: lazy(() => import('../pages/settings/countries/Country')),
	STATE: lazy(() => import('../pages/settings/states/State')),
	CITY: lazy(() => import('../pages/settings/cities/City')),
	LOCATION: lazy(() => import('../pages/settings/locations/Location')),
	AUDIT: lazy(() => import('../pages/settings/audit/Audit')),
	SHOWLOG: lazy(() => import('../pages/settings/audit/ShowLog')),
};

const SUBUSER = {
	SUBUSER: lazy(() => import('../pages/subuser/SubUser')),
};

const ACCOUNTS = {
	VEHICLE_INVOICE: lazy(() => import('../pages/accounts/vehicle-invoce/VehicleInvoice')),
	SERVICE_INVOICE: lazy(() => import('../pages/accounts/service-invoice/ServiceInvoice')),

	INVOICE_SUMMARY: lazy(() => import('../pages/invoices/InvoiceSummary')),
	PAID_INVOICE: lazy(() => import('../pages/paidInvoice/PaidInvoice')),
	PARTIAL_PAID_INVOICE: lazy(() => import('../pages/partialPaidInvoice/PartialPaidInvoice')),
	UNPAID_INVOICE: lazy(() => import('../pages/unpaidInvoice/UnpaidInvoice')),
	INVOICES: lazy(() => import('../pages/invoices/Invoice')),
};

const VEHICLES = {
	VEHICLE: lazy(() => import('../pages/vehicles/Vehicles')),
	SHOWVEHICLE: lazy(() => import('../pages/vehicles/ShowVehicle')),
	LOADINGVEHICLE: lazy(() => import('../pages/vehicles/LoadingVehicle')),
	VEHICLEWEIGHT: lazy(() => import('../pages/weights/Weights')),
	SHOW_WEIGHT: lazy(() => import('../pages/weights/ShowWeight')),
};
const NOTES = {
	NOTE: lazy(() => import('../pages/notes/Notes')),
};

const PORTS = {
	PORT: lazy(() => import('../pages/settings/ports/Ports')),
	// SHOWVEHICLE: lazy(() => import('../pages/vehicles/ShowVehicle')),
};

const EXPORTS = {
	EXPORTS: lazy(() => import('../pages/exports/Exports')),
	SHOWEXPORT: lazy(() => import('../pages/exports/ShowExport')),
};

const CONSIGNEES = {
	CONSIGNEE: lazy(() => import('../pages/consignees/Consignee')),
	SHOWCONSIGNEE: lazy(() => import('../pages/consignees/ShowConsignee')),
};

const NOTIFICATIONS = {
	NOTIFICATION: lazy(() => import('../pages/notifications/Notifications')),
	SHOW_NOTIFICATION: lazy(() => import('../pages/notifications/ShowNotification')),
};

const PROFILES = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const CUSTOMERS = {
	CUSTOMER: lazy(() => import('../pages/customers/Customers')),
	SHOW_CUSTOMER: lazy(() => import('../pages/customers/ShowCustomer')),
};
const CONTAINERS = {
	CONTAINER: lazy(() => import('../pages/container/Containers')),
};

const GATEPASS = {
	GATEPASS: lazy(() => import('../pages/gatepass/GatePass')),
};
const VCCS = {
	VCC: lazy(() => import('../pages/vcc/Vccs')),
};
const DAMAGECLAIMS = {
	DAMAGECLAIM: lazy(() => import('../pages/damageClaim/DamageClaim')),
	DAMAGECLAIM_SHOW: lazy(() => import('../pages/damageClaim/ShowDamageClaim')),
};
const KEYMISSINGCLAIMS = {
	KEYMISSINGCLAIMS: lazy(() => import('../pages/keyMissingClaim/KeyMissingClaim')),
	KEYMISSINGCLAIMS_SHOW: lazy(() => import('../pages/keyMissingClaim/ShowKeyMissingClaim')),
};
const STORAGECLAIMS = {
	STORAGECLAIMS: lazy(() => import('../pages/storageClaim/StorageClaim')),
	STORAGECLAIMS_SHOW: lazy(() => import('../pages/storageClaim/ShowStorageClaim')),
};
const PRICHING = {
	PRICHING: lazy(() => import('../pages/pricing/Priching')),
};
const CUSTOMERTITLESTATUS = {
	CUSTOMERTITLESTATUS: lazy(() => import('../pages/customerTitleStatus/CustomerTitleStatus')),
};
const PRICES = {
	CLEARANCE: lazy(() => import('../pages/pricing/ClearanceRate/ClearanceRate')),
	SHIPPINGRATE: lazy(() => import('../pages/pricing/shippingRate/ShippingRate')),
	SHOWSHIPPINGRATE: lazy(() => import('../pages/pricing/shippingRate/ShowShippingRate')),
	TOWING: lazy(() => import('../pages/pricing/towingRate/TowingRate')),
	SHOWTOWINGRATE: lazy(() => import('../pages/pricing/towingRate/ShowTowingRate')),

	GETPRICE: lazy(() => import('../pages/pricing/GetPrice/getPrice')),
};
const COMPLAINS = {
	COMPLAIN: lazy(() => import('../pages/complains/Complains')),
	COMPLAIN_SHOW: lazy(() => import('../pages/complains/ShowComplains')),
};

const FEEDBACK = {
	FEEDBACK: lazy(() => import('../pages/feedback/Feedback')),
	FEEDBACK_SHOW: lazy(() => import('../pages/feedback/ShowFeedback')),
};

const CHECKOUTS = {
	CHECKOUT: lazy(() => import('../pages/checkouts/Checkout')),
};

const ADMINCHECKOUTS = {
	ADMINCHECKOUT: lazy(() => import('../pages/checkouts/AdminCheckout')),
};

const LOADPLANS = {
	LOADPLAN: lazy(() => import('../pages/loadplans/LoadPlan')),
	SHOWLOADPLAN: lazy(() => import('../pages/loadplans/ShowLoadPlan')),
};

const ACCOUNTING = {
	INVOICE_VEHICLE: lazy(() => import('../pages/accounting/InvoiceVehicle')),
	INVOICE_SERVICE: lazy(() => import('../pages/accounting/ServiceInvoice')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */

	// {
	// 	path: accounts.singlePages.subMenu.serviceInvoice.path,
	// 	element: <ACCOUNTS.SERVICE_INVOICE />,
	// 	exact: true,
	// },
	// {
	// 	path: accounts.singlePages.subMenu.vehicleInvoice.path,
	// 	element: <ACCOUNTS.VEHICLE_INVOICE />,
	// 	exact: true,
	// },

	{
		path: accounts.singlePages.subMenu.InvoiceSummery.path,
		element: <ACCOUNTS.INVOICE_SUMMARY />,
		exact: true,
	},
	// PAIN_INVOICE
	{
		path: accounts.singlePages.subMenu.PaidInvoice.path,
		element: <ACCOUNTS.PAID_INVOICE />,
		exact: true,
	},
	{
		path: accounts.singlePages.subMenu.PartialPaidInvoice.path,
		element: <ACCOUNTS.PARTIAL_PAID_INVOICE />,
		exact: true,
	},
	{
		path: accounts.singlePages.subMenu.UnpaidInvoice.path,
		element: <ACCOUNTS.UNPAID_INVOICE />,
		exact: true,
	},
	{
		path: 'accounts/invoices/:user_id',
		element: <ACCOUNTS.INVOICES />,
		exact: true,
	},

	{
		path: settings.singlePages.subMenu.user.path,
		element: <SETTINGS.USER />,
		exact: true,
	},
	{
		path: 'sub-users',
		element: <SUBUSER.SUBUSER />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.role.path,
		element: <SETTINGS.ROLE />,
		exact: true,
	},
	{
		path: 'settings/roles-update',
		element: <SETTINGS.ROLE_UPDATE />,
		exact: true,
	},
	{
		path: 'settings/user-permission',
		element: <SETTINGS.USER_ROLE />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.country.path,
		element: <SETTINGS.COUNTRY />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.state.path,
		element: <SETTINGS.STATE />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.city.path,
		element: <SETTINGS.CITY />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.location.path,
		element: <SETTINGS.LOCATION />,
		exact: true,
	},
	{
		path: vehicles.singlePages.path,
		element: <VEHICLES.VEHICLE />,
		exact: true,
	},
	{
		path: '/vehicles/:id',
		element: <VEHICLES.SHOWVEHICLE />,
		exact: true,
	},
	{
		path: '/loading-vehicle',
		element: <VEHICLES.LOADINGVEHICLE />,
		exact: true,
	},
	{
		path: '/weights',
		element: <VEHICLES.VEHICLEWEIGHT />,
		exact: true,
	},
	{
		path: '/weights/:id',
		element: <VEHICLES.SHOW_WEIGHT />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.ports.path,
		element: <PORTS.PORT />,
		exact: true,
	},
	{
		path: settings.singlePages.subMenu.auditLog.path,
		element: <SETTINGS.AUDIT />,
		exact: true,
	},
	{
		path: '/settings/activity-logs/:id',
		element: <SETTINGS.SHOWLOG />,
		exact: true,
	},
	{
		path: exports.singlePages.path,
		element: <EXPORTS.EXPORTS />,
		exact: true,
	},
	{
		path: '/exports/:id',
		element: <EXPORTS.SHOWEXPORT />,
		exact: true,
	},
	{
		path: consignee.singlePages.path,
		element: <CONSIGNEES.CONSIGNEE />,
		exact: true,
	},
	{
		path: '/consignees/:id',
		element: <CONSIGNEES.SHOWCONSIGNEE />,
		exact: true,
	},
	{
		path: '/notes',
		element: <NOTES.NOTE />,
		exact: true,
	},
	{
		path: 'notifications',
		element: <NOTIFICATIONS.NOTIFICATION />,
		exact: true,
	},
	{
		path: '/notifications/:id',
		element: <NOTIFICATIONS.SHOW_NOTIFICATION />,
		exact: true,
	},

	{
		path: profiles.singlePages.path,
		element: <PROFILES.PROFILE />,
		exact: true,
	},
	{
		path: customers.singlePages.path,
		element: <CUSTOMERS.CUSTOMER />,
		exact: true,
	},
	{
		path: '/customers/:id',
		element: <CUSTOMERS.SHOW_CUSTOMER />,
		exact: true,
	},
	{
		path: customerTitleStatus.singlePages.path,
		element: <CUSTOMERTITLESTATUS.CUSTOMERTITLESTATUS />,
		exact: true,
	},
	{
		path: containers.singlePages.path,
		element: <CONTAINERS.CONTAINER />,
		exact: true,
	},
	{
		path: gatepass.singlePages.path,
		element: <GATEPASS.GATEPASS />,
		exact: true,
	},
	{
		path: vcc.singlePages.path,
		element: <VCCS.VCC />,
		exact: true,
	},
	{
		path: '/damage-claims/:id',
		element: <DAMAGECLAIMS.DAMAGECLAIM_SHOW />,
		exact: true,
	},
	{
		path: damageClaim.singlePages.path,
		element: <DAMAGECLAIMS.DAMAGECLAIM />,
		exact: true,
	},
	{
		path: '/storage-claims/:id',
		element: <STORAGECLAIMS.STORAGECLAIMS_SHOW />,
		exact: true,
	},
	{
		path: keyMissingClaim.singlePages.path,
		element: <KEYMISSINGCLAIMS.KEYMISSINGCLAIMS />,
		exact: true,
	},
	{
		path: '/key-missing-claims/:id',
		element: <KEYMISSINGCLAIMS.KEYMISSINGCLAIMS_SHOW />,
		exact: true,
	},
	{
		path: storageClaim.singlePages.path,
		element: <STORAGECLAIMS.STORAGECLAIMS />,
		exact: true,
	},
	{
		path: pricing.singlePages.subMenu.shippingRate.path,
		element: <PRICES.SHIPPINGRATE />,
		exact: true,
	},
	{
		path: 'price-inquiry',
		element: <PRICES.GETPRICE />,
		exact: true,
	},
	{
		path: '/shipping-rates/:id',
		element: <PRICES.SHOWSHIPPINGRATE />,
		exact: true,
	},
	{
		path: pricing.singlePages.subMenu.clearanceRate.path,
		element: <PRICES.CLEARANCE />,
		exact: true,
	},
	{
		path: pricing.singlePages.subMenu.towingRate.path,
		element: <PRICES.TOWING />,
		exact: true,
	},
	{
		path: '/towing-rates/:id',
		element: <PRICES.SHOWTOWINGRATE />,
		exact: true,
	},
	{
		path: complains.singlePages.path,
		element: <COMPLAINS.COMPLAIN />,
		exact: true,
	},
	{
		path: '/complains/:id',
		element: <COMPLAINS.COMPLAIN_SHOW />,
		exact: true,
	},
	{
		path: '/feedbacks',
		element: <FEEDBACK.FEEDBACK />,
		exact: true,
	},
	{
		path: '/feedbacks/:id',
		element: <FEEDBACK.FEEDBACK_SHOW />,
		exact: true,
	},
	{
		path: '/checkouts',
		element: <CHECKOUTS.CHECKOUT />,
		exact: true,
	},
	{
		path: '/admin-checkouts',
		element: <ADMINCHECKOUTS.ADMINCHECKOUT />,
		exact: true,
	},
	{
		path: loadPlan.singlePages.path,
		element: <LOADPLANS.LOADPLAN />,
		exact: true,
	},
	{
		path: 'load-plans/:id',
		element: <LOADPLANS.SHOWLOADPLAN />,
		exact: true,
	},
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
	{
		path: 'vehicle-invoice',
		element: <ACCOUNTING.INVOICE_VEHICLE />,
		exact: true,
	},
	{
		path: 'service-invoice',
		element: <ACCOUNTING.INVOICE_SERVICE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
