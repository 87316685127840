import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from '../../store/store';
import { userLoggedOut } from '../auth/authSlice';

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_BASE_URL,

	prepareHeaders: (headers, { getState }) => {
		let token = (getState() as RootState).auth.accessToken;

		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}

		headers.set('Content-type', `application/json`);
		headers.set('Accept', `application/json`);
		headers.set('platform', `WEB`);

		return headers;
	},
}) as BaseQueryFn<string | FetchArgs, unknown, any, {}>;

export const apiSlice = createApi({
	reducerPath: 'api',

	baseQuery: async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);

		if (result?.error?.status === 401) {
			api.dispatch(userLoggedOut());
			if (api.endpoint !== 'login') {
				window.location.reload();
			}
		}

		return result;
	},

	tagTypes: [
		'complains',
		'makes',
		'invoices',
		'models',
		'sellers',
		'members',
		'vehicles',
		'countries',
		'states',
		'cities',
		'locations',
		'activeLocations',
		'consignees',
		'customers',
		'showVehicles',
		'showExport',
		'showCustomers',
		'containers',
		'pricing',
		'clearance-rates',
		'vehicleCharts',
		'load-plans',
		'prices',
		'invoice-summary',
		'weights',
		'feedbacks',
		'vccs',
		'colors',
	],
	endpoints: () => ({}),
});

const baseQueryMultipart = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_BASE_URL,

	prepareHeaders: (headers, { getState }) => {
		let token = (getState() as RootState).auth.accessToken;

		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}

		headers.set('Content-type', 'multipart/form-data');
		headers.set('Accept', 'application/json');

		return headers;
	},
}) as BaseQueryFn<string | FetchArgs, unknown, any, {}>;

export const apiSliceFile = createApi({
	reducerPath: 'apiFile',

	baseQuery: async (args, api, extraOptions) => {
		const result = await baseQueryMultipart(args, api, extraOptions);

		if (result?.error?.status === 401) {
			api.dispatch(userLoggedOut());
			window.location.reload();
		}

		return result;
	},
	endpoints: () => ({}),
});
